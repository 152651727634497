import { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import AuthContextProvider from '../../auth-context';

import Loading from '../loading';
import Navbar from '../navbar';
import NavItems from '../navbar/nav-items';

import './app.scss';

const AuditQuestion = lazy(() => import('../../containers/audit-question'));
const AuditQuestions = lazy(() => import('../../containers/audit-questions'));
const Branch = lazy(() => import('../../containers/branch'));
const Branches = lazy(() => import('../../containers/branches'));
const Columns = lazy(() => import('../../containers/columns'));
const ControlProfile = lazy(() => import('../../containers/control-profile'));
const ControlProfileNorm = lazy(() => import('../../containers/control-profile-norm'));
const ControlProfiles = lazy(() => import('../../containers/control-profiles'));
const ControlProfilesPreview = lazy(() => import('../../containers/control-profiles-preview'));
const DataOption = lazy(() => import('../../containers/data-option'));
const DataOptions = lazy(() => import('../../containers/data-options'));
const DataSubject = lazy(() => import('../../containers/data-subject'));
const DataSubjects = lazy(() => import('../../containers/data-subjects'));
const DeviationQuestion = lazy(() => import('../../containers/deviation-question'));
const DeviationQuestions = lazy(() => import('../../containers/deviation-questions'));
const Document = lazy(() => import('../../containers/document'));
const Documents = lazy(() => import('../../containers/documents'));
const DpiaProject = lazy(() => import('../../containers/dpia-project'));
const DpiaProjects = lazy(() => import('../../containers/dpia-projects'));
const DpiaQuestion = lazy(() => import('../../containers/dpia-question'));
const DpiaQuestions = lazy(() => import('../../containers/dpia-questions'));
const EditNorm = lazy(() => import('../../containers/edit-norm'));
const ExtendedManualParagraphContent = lazy(() => import('../../containers/extended-manual-paragraph-content'));
const ExtendedNormContent = lazy(() => import('../../containers/extended-norm-content'));
const Gatekeeper = lazy(() => import('../gatekeeper'));
const JobRole = lazy(() => import('../../containers/job-role'));
const JobRoleProcessInvolved = lazy(() => import('../../containers/job-role-process-involved'));
const JobRoles = lazy(() => import('../../containers/job-roles'));
const JobTitle = lazy(() => import('../../containers/job-title'));
const JobTitleJobRole = lazy(() => import('../../containers/job-title-job-role'));
const JobTitles = lazy(() => import('../../containers/job-titles'));
const ManualChapter = lazy(() => import('../../containers/manual-chapter'));
const ManualChapters = lazy(() => import('../../containers/manual-chapters'));
const ManualParagraph = lazy(() => import('../../containers/manual-paragraph'));
const ManualParagraphPreview = lazy(() => import('../../containers/manual-paragraph-preview'));
const ManualParagraphProcessInvolved = lazy(() => import('../../containers/manual-paragraph-process-involved'));
const ManualParagraphProcessInvolveds = lazy(() => import('../../containers/manual-paragraph-process-involveds'));
const NewAuditQuestion = lazy(() => import('../../containers/new-audit-question'));
const NewBranch = lazy(() => import('../../containers/new-branch'));
const NewControlProfile = lazy(() => import('../../containers/new-control-profile'));
const NewDataOption = lazy(() => import('../../containers/new-data-option'));
const NewDataSubject = lazy(() => import('../../containers/new-data-subject'));
const NewDeviationQuestion = lazy(() => import('../../containers/new-deviation-question'));
const NewDocument = lazy(() => import('../../containers/new-document'));
const NewDpiaProject = lazy(() => import('../../containers/new-dpia-project'));
const NewDpiaQuestion = lazy(() => import('../../containers/new-dpia-question'));
const NewJobRole = lazy(() => import('../../containers/new-job-role'));
const NewJobRoleProcessInvolved = lazy(() => import('../../containers/new-job-role-process-involved'));
const NewJobTitle = lazy(() => import('../../containers/new-job-title'));
const NewJobTitleJobRole = lazy(() => import('../../containers/new-job-title-job-role'));
const NewManualChapter = lazy(() => import('../../containers/new-manual-chapter'));
const NewManualParagraphProcessInvolved = lazy(() => import('../../containers/new-manual-paragraph-process-involved'));
const NewNorm = lazy(() => import('../../containers/new-norm'));
const NewNormColumn = lazy(() => import('../../containers/new-norm-column'));
const NewNormRequirement = lazy(() => import('../../containers/new-norm-requirement'));
const NewProcessFlow = lazy(() => import('../../containers/new-process-flow'));
const NewResourceCategory = lazy(() => import('../../containers/new-resource-category'));
const NewRiskCategory = lazy(() => import('../../containers/new-risk-category'));
const NewSupplierLicenseExport = lazy(() => import('../../containers/new-supplier-license-export'));
const NewTable = lazy(() => import('../../containers/new-table'));
const NewTaskPackage = lazy(() => import('../../containers/new-task-package'));
const NewTaskPackageTask = lazy(() => import('../../containers/new-task-package-task'));
const Norm = lazy(() => import('../../containers/norm'));
const NormChapter = lazy(() => import('../../containers/norm-chapter'));
const NormColumn = lazy(() => import('../../containers/norm-column'));
const NormLinks = lazy(() => import('../../containers/norm-links'));
const NormRequirement = lazy(() => import('../../containers/norm-requirement'));
const NormRequirements = lazy(() => import('../../containers/norm-requirements'));
const Norms = lazy(() => import('../../containers/norms'));
const ProcessFlow = lazy(() => import('../../containers/process-flow'));
const ProcessFlows = lazy(() => import('../../containers/process-flows'));
const ResourceCategories = lazy(() => import('../../containers/resource-categories'));
const ResourceCategory = lazy(() => import('../../containers/resource-category'));
const RiskCategories = lazy(() => import('../../containers/risk-categories'));
const RiskCategory = lazy(() => import('../../containers/risk-category'));
const SupplierLicenseExport = lazy(() => import('../../containers/supplier-license-export'));
const SupplierLicenseExports = lazy(() => import('../../containers/supplier-license-exports'));
const Table = lazy(() => import('../../containers/table'));
const TableTextBlocks = lazy(() => import('../../containers/table-text-blocks'));
const Tables = lazy(() => import('../../containers/tables'));
const TaskPackageTask = lazy(() => import('../../containers/task-package-task'));
const TaskPackage = lazy(() => import('../../containers/task-package'));
const TaskPackages = lazy(() => import('../../containers/task-packages'));
const TextBlock = lazy(() => import('../../containers/text-block'));

const App = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Route path="/gatekeeper/callback" component={Gatekeeper} exact={true} />
        <AuthContextProvider>
          <Navbar />
          <div className="container-fluid">
            <div className="row main-container">
              <div className="col-1 sidebar sidebar__navbar d-none d-lg-block">
                <ul className="sidebar__navbar__menu">
                  <NavItems />
                </ul>
              </div>
              <div className="col main-content">
                <Switch>
                  <Route path="/audit-questions" component={AuditQuestions} exact={true} />
                  <Route path="/audit-questions/new" component={NewAuditQuestion} exact={true} />
                  <Route path="/audit-questions/:id" component={AuditQuestion} exact={true} />
                  <Route path="/branches" component={Branches} exact={true} />
                  <Route path="/branches/new" component={NewBranch} exact={true} />
                  <Route path="/branches/:id" component={Branch} exact={true} />
                  <Route path="/data-options" component={DataOptions} exact={true} />
                  <Route path="/data-options/new" component={NewDataOption} exact={true} />
                  <Route path="/data-options/:id" component={DataOption} exact={true} />
                  <Route path="/data-subjects" component={DataSubjects} exact={true} />
                  <Route path="/data-subjects/new" component={NewDataSubject} exact={true} />
                  <Route path="/data-subjects/:id" component={DataSubject} exact={true} />
                  <Route path="/deviation-questions" component={DeviationQuestions} exact={true} />
                  <Route path="/deviation-questions/new" component={NewDeviationQuestion} exact={true} />
                  <Route path="/deviation-questions/:id" component={DeviationQuestion} exact={true} />
                  <Route path="/documents" component={Documents} exact={true} />
                  <Route path="/documents/new" component={NewDocument} exact={true} />
                  <Route path="/documents/:id" component={Document} exact={true} />
                  <Route path="/dpia-projects" component={DpiaProjects} exact={true} />
                  <Route path="/dpia-projects/new" component={NewDpiaProject} exact={true} />
                  <Route path="/dpia-projects/:id" component={DpiaProject} exact={true} />
                  <Route path="/dpia-projects/:dpiaProjectId/dpia-questions" component={DpiaQuestions} exact={true} />
                  <Route
                    path="/dpia-projects/:dpiaProjectId/dpia-questions/new"
                    component={NewDpiaQuestion}
                    exact={true}
                  />
                  <Route
                    path="/dpia-projects/:dpiaProjectId/dpia-questions/:id"
                    component={DpiaQuestion}
                    exact={true}
                  />
                  <Route path="/job-roles" component={JobRoles} exact={true} />
                  <Route path="/job-roles/new" component={NewJobRole} exact={true} />
                  <Route path="/job-roles/:id" component={JobRole} exact={true} />
                  <Route
                    path="/job-roles/:jobRoleId/process-involveds/new"
                    component={NewJobRoleProcessInvolved}
                    exact={true}
                  />
                  <Route
                    path="/job-roles/:jobRoleId/process-involveds/:id"
                    component={JobRoleProcessInvolved}
                    exact={false}
                  />
                  <Route path="/job-titles" component={JobTitles} exact={true} />
                  <Route path="/job-titles/new" component={NewJobTitle} exact={true} />
                  <Route path="/job-titles/:id" component={JobTitle} exact={true} />
                  <Route
                    path="/job-titles/:jobTitleId/job-title-job-roles/new"
                    component={NewJobTitleJobRole}
                    exact={true}
                  />
                  <Route
                    path="/job-titles/:jobTitleId/job-title-job-roles/:id"
                    component={JobTitleJobRole}
                    exact={false}
                  />
                  <Route path="/manual-chapters" component={ManualChapters} exact={true} />
                  <Route path="/manual-chapters/new" component={NewManualChapter} exact={true} />
                  <Route path="/manual-chapters/:id" component={ManualChapter} exact={true} />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:id"
                    component={ManualParagraph}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/extended-norm-content"
                    component={ExtendedNormContent}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/process-involveds"
                    component={ManualParagraphProcessInvolveds}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/process-involveds/new"
                    component={NewManualParagraphProcessInvolved}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/process-involveds/:id"
                    component={ManualParagraphProcessInvolved}
                    exact={false}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/preview"
                    component={ManualParagraphPreview}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/norm-links"
                    component={NormLinks}
                    exact={true}
                  />
                  <Route
                    path="/manual-chapters/:manualChapterId/manual-paragraphs/:manualParagraphId/text-blocks/:id"
                    component={TextBlock}
                    exact={true}
                  />
                  <Route path="/norms" component={Norms} exact={true} />
                  <Route path="/norms/new" component={NewNorm} exact={true} />
                  <Route path="/norms/:id" component={Norm} exact={true} />
                  <Route path="/norms/:id/edit" component={EditNorm} exact={true} />
                  <Route path="/norms/:normId/extededContent" component={ExtendedManualParagraphContent} exact={true} />
                  <Route path="/norms/:normId/norm-chapters/:id" component={NormChapter} exact={true} />
                  <Route path="/norms/:normId/norm-columns/new" component={NewNormColumn} exact={true} />
                  <Route path="/norms/:normId/norm-columns/:id" component={NormColumn} exact={true} />
                  <Route path="/control-profiles" component={ControlProfiles} exact={true} />
                  <Route path="/control-profiles/new" component={NewControlProfile} exact={true} />
                  <Route path="/control-profiles/preview" component={ControlProfilesPreview} exact={true} />
                  <Route path="/control-profiles/:id" component={ControlProfile} exact={true} />
                  <Route
                    path="/control-profiles/:controlProfileId/control-profile-norms/:id"
                    component={ControlProfileNorm}
                    exact={true}
                  />
                  <Route path="/norm-requirements" component={NormRequirements} exact={true} />
                  <Route path="/norm-requirements/new" component={NewNormRequirement} exact={true} />
                  <Route path="/norm-requirements/:id" component={NormRequirement} exact={true} />
                  <Route path="/process-flows" component={ProcessFlows} exact={true} />
                  <Route path="/process-flows/new" component={NewProcessFlow} exact={true} />
                  <Route path="/process-flows/:id" component={ProcessFlow} exact={true} />
                  <Route path="/resource-categories" component={ResourceCategories} exact={true} />
                  <Route path="/resource-categories/new" component={NewResourceCategory} exact={true} />
                  <Route path="/resource-categories/:id" component={ResourceCategory} exact={true} />
                  <Route path="/risk-categories" component={RiskCategories} exact={true} />
                  <Route path="/risk-categories/new" component={NewRiskCategory} exact={true} />
                  <Route path="/risk-categories/:id" component={RiskCategory} exact={true} />
                  <Route path="/supplier-license-exports" component={SupplierLicenseExports} exact={true} />
                  <Route path="/supplier-license-exports/new" component={NewSupplierLicenseExport} exact={true} />
                  <Route path="/supplier-license-exports/:id" component={SupplierLicenseExport} exact={true} />
                  <Route path="/tables" component={Tables} exact={true} />
                  <Route path="/tables/new" component={NewTable} exact={true} />
                  <Route path="/tables/:id" component={Table} exact={true} />
                  <Route path="/tables/:tableId/columns" component={Columns} exact={true} />
                  <Route path="/tables/:tableId/text-blocks" component={TableTextBlocks} exact={true} />
                  <Route path="/task-packages/:taskPackageId/tasks/new" component={NewTaskPackageTask} exact={false} />
                  <Route path="/task-packages/:taskPackageId/tasks/:taskId" component={TaskPackageTask} exact={false} />
                  <Route path="/task-packages" component={TaskPackages} exact={true} />
                  <Route path="/task-packages/new" component={NewTaskPackage} exact={true} />
                  <Route path="/task-packages/:id" component={TaskPackage} exact={true} />
                  <Redirect from="*" to="/" />
                </Switch>
              </div>
            </div>
          </div>
        </AuthContextProvider>
      </Suspense>
    </Router>
  );
};

export default App;
