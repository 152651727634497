import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './i18n';
import './index.scss';
import App from './components/app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

window.AvailableLocales = ['nl', 'en'];

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://b47b02e9fb9a4865abf8690a6cb6ed06@o248658.ingest.sentry.io/1765431',
    release: 'manuals@' + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: null,
  });
}

createRoot(document.getElementById('root')).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
